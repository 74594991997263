<template>
    <div class="operations-modal-content__container">
        <div class="operations-modal-content__header">
            <div class="operations-modal-content__filter-input-container">
                <SearchIcon class="operations-modal-content__search-icon" />
                <b-form-tags
                    ref="formTagsOrders"
                    :value="operationsModal.filterInput"
                    class="operations-modal-content__filter-input"
                    duplicate-tag-text=""
                    :placeholder="
                        operationsModal.filterInput.length === 0
                            ? $t('ProductionProgress.SearchForOrder')
                            : ''
                    "
                    remove-on-delete
                    @input="filterOperations"
                />
            </div>

            <div class="operations-modal-content__scroll-title">
                <span>{{ $t("ProductionProgress.Order") }}</span>
            </div>
        </div>

        <div class="operations-modal-content__list-container">
            <operation-card
                v-for="(
                    operation, index
                ) in operationsModal.filteredOperationsList"
                :key="`operations-${index}`"
                :operation="operation"
                :checked="
                    operationsModal.selectedOperationIds.includes(operation.id)
                "
                @change="handleCardChange"
            />
        </div>

        <div
            class="operations-modal-content__not-found-container"
            v-if="operationsModal.filteredOperationsList.length == 0"
        >
            <div class="not-found">
                <NoResultIcon />
                <h3>{{ $t("NoResultsFound") }}</h3>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import SearchIcon from "@core/assets/formatted-icons/search-icon.svg";
import { BFormTags } from "bootstrap-vue";
import NoResultIcon from "@core/assets/formatted-icons/no-result-icon.svg";
import OperationCard from "./OperationCard.vue";

export default {
    components: {
        BFormTags,
        SearchIcon,
        NoResultIcon,
        OperationCard,
    },
    computed: {
        ...mapGetters("production_progress", ["operationsModal"]),
    },
    data: () => ({}),
    methods: {
        ...mapMutations("production_progress", [
            "UPDATE_OPERATIONS_MODAL_SELECTED_OPERATION_IDS",
            "UPDATE_OPERATIONS_MODAL_FILTER_INPUT",
            "UPDATE_OPERATIONS_MODAL_FILTERED_OPERATIONS_LIST",
        ]),
        normalizeString(str) {
            return str
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
                .toLowerCase();
        },
        filterOperations(filters) {
            this.UPDATE_OPERATIONS_MODAL_FILTER_INPUT([...filters]);
            const operationsLocal = [...this.operationsModal.operations];

            if (filters.length === 0) {
                this.operationsModal.filteredOperationsList = operationsLocal;
                return;
            }

            const searchTerms = filters.map(this.normalizeString);

            const filteredList = operationsLocal.filter((item) => {
                const normalizedItemName = this.normalizeString(item.name);
                return searchTerms.some((term) =>
                    normalizedItemName.includes(term)
                );
            });

            this.UPDATE_OPERATIONS_MODAL_FILTERED_OPERATIONS_LIST(filteredList);
        },
        handleCardChange(operation) {
            const selectedOperationIdsLocal = [
                ...this.operationsModal.selectedOperationIds,
            ];

            const operationId = selectedOperationIdsLocal.findIndex(
                (id) => id === operation.id
            );

            if (!operation.checked) {
                selectedOperationIdsLocal.splice(operationId, 1);
                this.UPDATE_OPERATIONS_MODAL_SELECTED_OPERATION_IDS(
                    selectedOperationIdsLocal
                );
                return;
            }

            selectedOperationIdsLocal.push(operation.id);
            this.UPDATE_OPERATIONS_MODAL_SELECTED_OPERATION_IDS(
                selectedOperationIdsLocal
            );
        },
    },
    mounted() {
        const vm = this;
        $(() => {
            document
                .getElementsByClassName(
                    "operations-modal-content__filter-input"
                )[0]
                .setAttribute("tabIndex", "-1");

            const slider = document.querySelector(".b-form-tags-list");
            let mouseDown = false;
            let startX;
            let scrollLeft;

            const startDragging = function (e) {
                mouseDown = true;
                startX = e.pageX - slider.offsetLeft;
                scrollLeft = slider.scrollLeft;
            };
            const stopDragging = function (event) {
                mouseDown = false;
            };

            slider.addEventListener("mousemove", (e) => {
                e.preventDefault();
                if (!mouseDown) {
                    return;
                }
                const x = e.pageX - slider.offsetLeft;
                const scroll = x - startX;
                slider.scrollLeft = scrollLeft - scroll;
            });

            slider.addEventListener("mousedown", startDragging, false);
            slider.addEventListener("mouseup", stopDragging, false);
            slider.addEventListener("mouseleave", stopDragging, false);

            $(".b-form-tags-input").blur((event) => {
                const input = vm.$refs.formTagsOrders;
                input.addTag(event.target.value);
            });
        });
    },
};
</script>

<style lang="scss">
#operations-modal {
    .operations-modal-content__filter-input-container {
        display: flex;

        .operations-modal-content__search-icon {
            min-height: 22px !important;
            min-width: 22px !important;
            max-height: 22px !important;
            max-width: 22px !important;
        }

        .operations-modal-content__filter-input {
            min-height: 51px;
            height: 100%;
            padding: 0;
            border-radius: 0;
            margin: 0;
            border: 0;

            ul {
                width: 100%;
                cursor: text !important;

                .b-form-tag {
                    margin-top: 0 !important;
                }
            }

            &.focus {
                border-color: #cfc4be !important;
                box-shadow: none;
            }

            &.b-form-tags {
                padding: 0 !important;
                display: flex;
                align-items: center !important;

                .b-form-tags-list {
                    width: 100% !important;
                    margin-top: 0;
                    height: 100% !important;
                    flex-wrap: nowrap !important;
                    display: flex !important;
                    align-items: center !important;
                    overflow: scroll;
                    overflow-y: hidden;
                    cursor: grabbing;
                    cursor: -webkit-grabbing;

                    &::-webkit-scrollbar {
                        display: none !important;
                    }

                    .b-from-tags-field {
                        margin: 9px 0;
                    }
                }
            }

            .b-form-tags-button {
                display: none;
            }

            .b-form-tag {
                background-color: #974900;
                margin-right: 0.6rem;
                padding: 2px 8px !important;
                font-size: 13px;
            }

            ::placeholder {
                color: #cfc4be;
            }
        }
    }
}

@media (max-width: 768px) and (min-width: 570px) {
    #operations-modal {
        .operations-modal-content__container {
            .operations-modal-content__filter-input-container {
                .operations-modal-content__filter-input {
                    min-height: 81px;
                }
            }
        }
    }
}

@media (max-width: 570px) {
    #operations-modal {
        .operations-modal-content__container {
            .operations-modal-content__filter-input-container {
                .operations-modal-content__filter-input {
                    min-height: 47px;
                }
            }
        }
    }
}
</style>

<style lang="scss" scoped>
#operations-modal {
    .operations-modal-content__container {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        overflow-y: auto;
        position: relative;

        &::-webkit-scrollbar {
            width: 5px;
        }

        &::-webkit-scrollbar-track {
            background: #fff;
        }

        &::-webkit-scrollbar-thumb {
            background-color: #cfc4be;
            border-radius: 9px;
        }

        .operations-modal-content__header {
            width: 100%;
            display: flex;
            flex-direction: column;
            position: sticky;
            top: 0;

            .operations-modal-content__scroll-title {
                width: 100%;
                padding: 8px 16px;
                display: flex;
                span {
                    color: #998f8a;
                    font-size: 14px;
                    font-weight: 600;
                    line-height: 20px;
                }
            }
        }

        .operations-modal-content__filter-input-container {
            width: 100%;
            background: #fff;
            border-bottom: 1px solid #cfc4be;
            display: flex;
            gap: 16px;
            padding: 0 29px;
            align-items: center;

            .operations-modal-content__search-icon {
                min-height: 16px;
                min-width: 16px;
                max-height: 16px;
                max-width: 16px;
                fill: #cfc4be;
            }
        }

        .operations-modal-content__list-container {
            display: flex;
            flex-direction: column;
        }

        .operations-modal-content__not-found-container {
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;

            .not-found {
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 24px;
                width: 100%;
                flex-direction: column;
                gap: 24px;

                h3 {
                    color: #4c4541;
                    font-size: 22px;
                    font-weight: 600;
                    line-height: 30px;
                    margin: 0;
                }

                svg {
                    height: 80px;
                    width: 80px;
                    fill: #4c4541;
                }
            }
        }
    }
}

@media (max-width: 768px) and (min-width: 570px) {
    #operations-modal {
        .operations-modal-content__container {
            .operations-modal-content__header {
                .operations-modal-content__scroll-title {
                    padding: 8px 29px !important;
                    span {
                        font-size: 16px !important;

                        line-height: 26px !important;
                    }
                }
            }

            .operations-modal-content__filter-input-container {
                .operations-modal-content__search-icon {
                    min-height: 22px !important;
                    min-width: 22px !important;
                    max-height: 22px !important;
                    max-width: 22px !important;
                }
            }
        }
    }
}

@media (max-width: 570px) {
    #operations-modal {
        .operations-modal-content__container {
            .operations-modal-content__header {
                .operations-modal-content__scroll-title {
                    padding: 8px 29px !important;

                    span {
                        font-size: 12px !important;
                        line-height: 16px !important;
                    }
                }
            }

            .operations-modal-content__filter-input-container {
                padding: 0 16px !important;

                .operations-modal-content__search-icon {
                    min-height: 14px !important;
                    min-width: 14px !important;
                    max-height: 14px !important;
                    max-width: 14px !important;

                    path {
                        fill: #cfc4be;
                    }
                }
            }

            .operations-modal-content__not-found-container {
                .not-found {
                    svg {
                        height: 50px !important;
                        width: 50px !important;
                    }

                    h3 {
                        font-size: 16px !important;
                        line-height: 26px !important;
                    }
                }
            }
        }
    }
}
</style>
