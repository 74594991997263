<template>
    <div
        class="operation-card__container"
        @click="$emit('change', { ...operation, checked: !checked })"
    >
        <div class="operation-card__content-left">
            <span class="operation-card__label">
                {{ operation.name }}
            </span>
        </div>
        <div class="operations-card__content-right">
            <div class="operation-card__checkbox-container">
                <sys-checkbox :size="handleCheckboxSize()" :checked="checked" />
            </div>
        </div>
    </div>
</template>

<script>
import SysCheckbox from "@/@core/components/sys-checkbox";
import { mapGetters } from "vuex";

export default {
    components: {
        SysCheckbox,
    },
    props: {
        checked: {
            type: Boolean,
            default: false,
        },
        operation: {
            type: Object,
            default: null,
        },
    },
    computed: {
        ...mapGetters("app", ["windowWidth"]),
    },
    methods: {
        handleCheckboxSize() {
            if (this.windowWidth <= 768 && this.windowWidth > 570) return "xl";

            return "md";
        },
    },
};
</script>

<style lang="scss" scoped>
#operations-modal {
    .operation-card__container {
        display: flex;
        width: 100%;
        padding: 16px;
        cursor: pointer;

        &:nth-child(even) {
            background: #fff;
        }

        &:nth-child(odd) {
            background: #fafafa;
        }

        .operation-card__content-left {
            display: flex;
            flex: 1;
            .operation-card__label {
                color: #4c4541;
                font-size: 16px;
                font-weight: 400;
                line-height: 22px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
        .operation-card__content-right {
            display: flex;
            .operation-card__checkbox-container {
                display: flex;
            }
        }
    }
}

@media (max-width: 768px) and (min-width: 571px) {
    #operations-modal {
        .operation-card__container {
            padding: 16px 29px !important;
            .operation-card__content-left {
                .operation-card__label {
                    font-size: 22px !important;
                    line-height: 30px !important;
                }
            }
        }
    }
}

@media (max-width: 570px) {
    #operations-modal {
        .operation-card__container {
            padding: 16px 29px !important;
            .operation-card__content-left {
                .operation-card__label {
                    font-size: 14px !important;
                    line-height: 20px !important;
                }
            }
        }
    }
}
</style>
