<template>
    <div class="operations-modal-footer__container">
        <div class="operations-modal-footer__content-left">
            <span
                class="operations-modal-footer__quantity"
                v-if="operationsModal.selectedOperationIds.length"
            >
                {{ operationsModal.selectedOperationIds.length }}
                {{ $t("ProductionProgress.SelectedOrders") }}
            </span>
            <span class="operations-modal-footer__quantity" v-else>
                {{ $t("ProductionProgress.NoOrdersSelected") }}
            </span>
        </div>
        <div class="operations-modal-footer__content-right">
            <sys-button
                @click="cancel()"
                scheme="square"
                :size="handleButtonSize()"
                variant="outline"
                :full="windowWidth <= 570"
            >
                {{ $t("ProductionProgress.Cancel") }}
            </sys-button>

            <sys-button
                @click="addOperations()"
                scheme="square"
                :size="handleButtonSize()"
                variant="contained"
                :full="windowWidth <= 570"
            >
                {{ $t("ProductionProgress.Add2") }}
            </sys-button>
        </div>
    </div>
</template>

<script>
import SysButton from "@/@core/components/sys-button";
import { mapGetters, mapActions } from "vuex";

export default {
    components: {
        SysButton,
    },
    computed: {
        ...mapGetters("app", ["windowWidth"]),
        ...mapGetters("production_progress", ["operationsModal"]),
    },
    methods: {
        ...mapActions("production_progress", [
            "UPDATE_OPERATIONS_MODAL_SELECTED_OPERATION_IDS",
        ]),
        handleButtonSize() {
            if (this.windowWidth >= 768) return "md";
            if (this.windowWidth < 768 && this.windowWidth > 570) return "xl";
            return "xs";
        },

        addOperations() {},
        cancel() {},
    },
};
</script>

<style lang="scss" scoped>
#operations-modal {
    .operations-modal-footer__container {
        display: flex;
        align-items: center;
        padding: 15.5px 29px;
        gap: 16px;
        border-top: 1px solid#CFC4BE;

        .operations-modal-footer__content-left {
            display: flex;
            .operations-modal-footer__quantity {
                color: #4c4541;
                font-size: 14px;
                font-weight: 600;
                line-height: 20px;
            }
        }

        .operations-modal-footer__content-right {
            margin-left: auto;
            display: flex;
            gap: 16px;
        }
    }
}

@media (max-width: 768px) and (min-width: 571px) {
    #operations-modal {
        .operations-modal-footer__container {
            .operations-modal-footer__content-left {
                .operations-modal-footer__quantity {
                    font-size: 22px !important;
                    line-height: 30px !important;
                }
            }
        }
    }
}

@media (max-width: 570px) {
    #operations-modal {
        .operations-modal-footer__container {
            justify-content: center;
            flex-direction: column !important;
            align-items: center;
            padding: 15.5px 16px;

            .operations-modal-footer__content-left {
                justify-content: center;
                .operations-modal-footer__quantity {
                    font-size: 12px !important;
                    line-height: 16px !important;
                }
            }

            .operations-modal-footer__content-right {
                gap: 8px !important;
                margin: 0 !important;
                width: 100%;
            }
        }
    }
}
</style>
