<template>
    <div class="operations-modal-header__wrapper">
        <div class="operations-modal-header__container">
            <div class="operations-modal-header__container-left">
                <div
                    @click="handleOnBack"
                    class="operations-modal-header__back-button-container"
                >
                    <ArrowIcon />
                </div>

                <span class="operations-modal-header__title">
                    {{ operationsModal.material.name }}</span
                >
            </div>

            <span
                @click="handleOnClose"
                class="operations-modal-header__close-icon"
            >
                <close-icon />
            </span>
        </div>
    </div>
</template>

<script>
import { BSidebar, VBToggle } from "bootstrap-vue";
import CloseIcon from "@core/assets/formatted-icons/close-icon.svg";
import ArrowIcon from "@core/assets/icons/arrow-icon.svg";
import { mapActions, mapGetters } from "vuex";

export default {
    components: {
        BSidebar,
        CloseIcon,
        ArrowIcon,
    },
    directives: {
        "b-toggle": VBToggle,
    },
    computed: { ...mapGetters("production_progress", ["operationsModal"]) },
    methods: {
        ...mapActions("production_progress", [
            "closeOperationsModal",
            "backToAddMaterialsModal",
        ]),
        handleOnClose() {
            this.closeOperationsModal();
        },
        handleOnBack() {
            this.backToAddMaterialsModal();
        },
    },
};
</script>

<style lang="scss">
#operations-modal {
    .b-sidebar-header {
        padding: 0 !important;
    }
}
</style>

<style lang="scss" scoped>
#operations-modal {
    .operations-modal-header__wrapper {
        display: flex;
        flex-direction: column;
        width: 100%;

        .operations-modal-header__container {
            display: flex;
            padding: 15.5px 29px;
            border-bottom: 1px solid #cfc4be;
            flex-wrap: nowrap;
            align-items: center;
            width: 100%;
            gap: 16px;

            .operations-modal-header__container-left {
                display: flex;
                align-items: center;
                gap: 16px;
                width: 100%;

                .operations-modal-header__back-button-container {
                    padding: 8px;
                    height: fit-content;
                    cursor: pointer;
                    display: flex;

                    svg {
                        min-width: 12px;
                        max-width: 12px;
                        min-height: 12px;
                        max-height: 12px;
                        fill: #974900;
                        transform: rotate(180deg);
                    }
                }

                .operations-modal-header__title {
                    font-size: 26px;
                    font-weight: 600;
                    line-height: 36px;
                    color: #4c4541;
                    width: 100%;
                }
            }

            .operations-modal-header__close-icon {
                cursor: pointer;
                display: flex;
                height: fit-content;

                svg {
                    min-width: 16px;
                    max-width: 16px;
                    min-height: 16px;
                    max-height: 16px;
                    fill: #4c4541;
                }
            }

            .operations-modal-header__separator {
                width: 100%;
                height: 1px;
                background-color: #e5e5e5;
                margin-top: 10px;
                margin-bottom: 10px;
            }
        }
    }
}

@media (max-width: 768px) and (min-width: 571px) {
    #operations-modal {
        .operations-modal-header__back-button-container {
            padding: 14px !important;
            svg {
                min-width: 18px !important;
                max-width: 18px !important;
                min-height: 18px !important;
                max-height: 18px !important;
            }
        }

        .operations-modal-header__title {
            font-size: 36px !important;
            line-height: 50px !important;
        }

        .operations-modal-header__close-icon {
            svg {
                min-width: 24px !important;
                max-width: 24px !important;
                min-height: 24px !important;
                max-height: 24px !important;
            }
        }
    }
}

@media (max-width: 570px) {
    #operations-modal {
        .operations-modal-header__container {
            padding: 15.5px 16px !important;
            .operations-modal-header__title {
                font-size: 18px !important;
                line-height: 26px !important;
            }

            .operations-modal-header__close-icon {
                svg {
                    min-width: 16px !important;
                    max-width: 16px !important;
                    min-height: 16px !important;
                    max-height: 16px !important;
                }
            }
        }
    }
}
</style>
